// extracted by mini-css-extract-plugin
export const root = "project-item-module--root--2G6gJ";
export const inView = "project-item-module--inView--xnG26";
export const disabled = "project-item-module--disabled--2Ryo9";
export const blur = "project-item-module--blur--dPTl1";
export const size25 = "project-item-module--size25--kwVQv";
export const size33 = "project-item-module--size33--2ZR_D";
export const size50 = "project-item-module--size50--8VLPP";
export const size66 = "project-item-module--size66--4Iejn";
export const size75 = "project-item-module--size75--ZbE9k";
export const size100 = "project-item-module--size100--o7rLx";
export const imageSliderItem = "project-item-module--imageSliderItem--2X0Gw";
export const player = "project-item-module--player--2QkSl";
export const text = "project-item-module--text--2-wAl";
export const creditTitle = "project-item-module--creditTitle--26r77";
export const titles = "project-item-module--titles--3XjA9";
export const hasMargin = "project-item-module--hasMargin--2mUWj";
export const credits = "project-item-module--credits--2YF-E";
export const isActive = "project-item-module--isActive--2J3uF";
export const creditsContainer = "project-item-module--creditsContainer--2nyIe";
export const highlighted = "project-item-module--highlighted--M6n6T";